/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            email
            instagram
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/aldi.daswanto.jpeg"
        width={50}
        height={50}
        quality={95}
        alt="Aldi Daswanto"
      />
      {author?.name && (
        <div>
          Ditulis oleh <strong>{author.name}</strong>
          {", "}
          {author.summary && (
            <span dangerouslySetInnerHTML={{ __html: author.summary }}></span>
          )}
          {social.instagram && (
            <>
              <br />
              <small>
                Follow saya di{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://www.instagram.com/${social.instagram}`}
                >
                  Instagram
                </a>, atau kontak saya via email di{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`mailto:aldi@daswanto.com`}
                >
                {social.email}
                </a>
              </small>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default Bio
